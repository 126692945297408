import PropTypes from 'prop-types';
import React from 'react';

import GlobalActivity from '../components/GlobalActivity/GlobalActivity';
import PageTitle from '../components/layout/PageTitle/PageTitle';
import SEO from '../components/seo';

const CountryPageTemplate = ({ pageContext }) => {

  const {
    country,
    dailyData,
    allDates,
    todayData,
    yesterdayData,
    countryGlobalCurrentStats,
    daily,
  } = pageContext;

  let lastDate = todayData.jour;

  const globalStats = {
    active: countryGlobalCurrentStats.active,
    cases: countryGlobalCurrentStats.cases,
    casesPerOneMillion: countryGlobalCurrentStats.casesPerOneMillion,
    // country
    critical: countryGlobalCurrentStats.critical,
    deaths: countryGlobalCurrentStats.deaths,
    deathsPerOneMillion: countryGlobalCurrentStats.deathsPerOneMillion,
    recovered: countryGlobalCurrentStats.recovered,
    todayCases: yesterdayData ? todayData.total - yesterdayData.total : null,
    todayDeaths: yesterdayData ? todayData.dc - yesterdayData.dc : null,
  };
  return (
    <>
      <SEO title={country} />

      <PageTitle title={country} />
      
      {globalStats && (
        <GlobalActivity
          data={{ allDates, lastDate, dataByDate: dailyData, daily }}
          globalStats={globalStats}
          cumulChartItem={['total', 'dc', 'rad']}
          dailyChartItems={['total', 'dc']}
        />
      )}
    </>
  );
};

CountryPageTemplate.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default CountryPageTemplate;
